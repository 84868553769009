.a4 {
    padding: 12.5mm 25mm;
    margin: 1em auto;
    display: block;
    box-shadow: 0 0 0.5em rgba(0,0,0,0.5);
}

.a4 .printable {
    /* margin: 12.5mm 25mm; */
    min-height: 100%;
    position: relative;
}

.moss {
    color: red;
}

.mnyAddr {
    text-align: right;
}

.mnyAddr p {
    margin-bottom: 0;
}

.terms {
    font-size: 0.8em;
}


footer {
    position: absolute;
    bottom: 0;
}

@media only screen and (min-width: 992px) {
    .a4 {
        width: 210mm;
        height: 296mm;
    }
}

/* STAMPS */

.stamp {
    border: 0.4rem solid;
    border-radius: 0.3125rem;
    text-transform: uppercase;
    padding: 0.25rem 1rem;
    font-family: Courier, monospace;
    font-size: 3rem;
    font-weight: 700;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* TODO: change the way the PDF rendering happens so that it is possible to rotate this. */
    /* transform: translate(-50%, -50%) rotate(-30deg); */
    color: #555;
    -webkit-mask-image: url(../grunge.png);
    mask-image: url(../grunge.png);
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-size: cover;
    mask-size: cover;
}

/* .stampPaid {
    transform: translate(-50%, -50%) rotate(-10deg);
} */

.stampOverdue {
    color: #d23;
}