.filters {
    margin: 1em 0;
}

.hover:hover {
    background-color: gainsboro;
}

.hover p {
    margin-bottom: 0 !important;
}

.overdue {
    background-color: rgba(255, 0, 0, 0.15);
    border-radius: 5px;
}